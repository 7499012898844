<template>
  <vca-card>
    <h2 @click="increment()">{{ $t("profile.edit.header") }}</h2>
    <Profile />
    <Flooding ref="flood" />
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
import Flooding from "@/components/utils/Flooding.vue";
import Profile from "@/components/account/AccountProfile";
export default {
  name: "AccountProfile",
  components: { Profile, Flooding },
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    increment() {
      if (!this.isBirthdate) {
        return;
      }
      this.count++;
      if (this.count == 5) {
        this.$refs.flood.startFilling();
      }
    },
    update() {
      this.$store.dispatch({ type: "user/address/update" });
    },
  },
  computed: {
    ...mapGetters({
      user: "user/current",
    }),
    isBirthdate() {
      const today = new Date();
      const currentDay = today.getDate();
      const currentMonth = today.getMonth();

      const birthday = new Date(this.user.profile.birthdate * 1000);
      const birthdayDay = birthday.getDate();
      const birthdayMonth = birthday.getMonth();

      return currentDay === birthdayDay && currentMonth === birthdayMonth;
    },
  },
  created() {
    this.$store.commit("user/newsletter/initial");
  },
};
</script>
